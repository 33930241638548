import React, { useEffect, useState } from "react";
import Home from "./Support";
import Header from './header';
import Grid from '@mui/material/Grid';
import { useAuth } from "../auth/authContext";


const SubAdmin = (props) => {
    const [isUserVerified, setIsUserVerified] = useState(true);
    const [loading, setLoading] = useState(true);
  
    const { currentUser } = useAuth();
    const userEmail = currentUser.email;
    const url = '';

    const checkUser = async () => {
        try{
            const response = await fetch(url);
            const data = await response.json();
            if(data.user){
                setIsUserVerified(true);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }


    useEffect(()=>{
        checkUser();
    });


    const data = {
        title: "Support",
        desc: "",
    }
    return (
        <>
        {loading? (
            <div>
            <p>Loading....</p>
            </div>
        ) :(
        <>
        {!isUserVerified? (
           <div>
           <p>Not Authorized to login.</p>
           </div>
        ):(
            <>
                <Grid container spacing={1} className='mainDiv d-block'>
                    <Grid xs={12} sm={8} md={9} className='maenedit'>
                        <Header data={data} props={props}/>
                        <Home props={props} />
                    </Grid>
                </Grid>
            </>
            )}
        </>
        )}
        </>
    );
}


export default SubAdmin;
import { io } from 'socket.io-client';

export const socket = io('https://socketio.dynaptsolutions.com:8000', {
    cors: {
        origin: "https://localhost:3000",
        credentials: true
    },
    transports: ["websocket"]
});
 
socket.on('connect', () => {
    console.log('connected', socket.id);
    socket.emit('joinSupport');
});


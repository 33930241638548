import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import style from '../Css/login.module.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Typography } from '@mui/material';
import MicrosoftLogin from 'react-microsoft-login';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosRetry from "axios-retry";
import { BASE_URL } from '../config';
import { toast } from 'react-toastify';
// import { BASE_URL } from '../config';


const Item = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const Signin = ({ onLogin }) => {

  const navigate = useNavigate()

  const onLoginPress = async () => {
    navigate("/support")
    // var body = {
    //     'user_name': formik.values.username,
    //     'password': formik.values.password
    // }
    // var headers = {
    //     "Content-Type": "application/json",
    // }
    // props.props.loaderRef(true)
    // var data = await ApiServices.PostApiCall(ApiEndpoint.LOGIN_USER, JSON.stringify(body), headers);
    // props.props.loaderRef(false)
    // console.log(data)
    // if (!!data) {
    //     if (data.status == true) {
    //         data.data.token = data.token
    //         props.save_user_data({ user: data.data });
    //         toast.success("Logged In Succesfully")
    //     } else {
    //         // setErrorShow(true)
    //         toast.error(data.message)
    //     }
    // } else {
    //     toast.error('Something went wrong.')
    // }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    // validationSchema: Yup.object({
    //     username: Yup
    //         .string()
    //         .max(255)
    //         .required(
    //             'Username is required'),
    //     password: Yup
    //         .string()
    //         .max(255)
    //         .required(
    //             'Password is required'),
    // }),
    onSubmit: () => {
      onLoginPress()
    },
  });

  async function getUserGroupID(accessToken) {
    console.log("called")
    axiosRetry(axios, { retries: 3 });
    try {
      const response = await axios.get('https://graph.microsoft.com/v1.0/me/memberOf', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        const groups = response.data.value;
        const groupIDs = groups.map((group) => group.id);
        return groupIDs;
      } else {
        throw new Error('Error retrieving user group ID: Response data not found');
      }
    } catch (error) {
      console.error('Error retrieving user group ID', error);
      throw error;
    }
  }


  const authHandler = async (error, data, msal) => {
    try {
      if (error) {
        console.error("Error during authentication:", error);
      }
      console.log("data:=====================>", data);
      console.log("msal:=====================>", msal);
  
      if (msal) {
        localStorage.setItem('MSAL', msal);
      }
  
      if (!error && data) {
        // Call the function to retrieve the user group ID
        const groupIDs = await getUserGroupID(data.accessToken);
  
        console.log('User group IDs:', groupIDs);
  
        let body = JSON.stringify({ UserName: data.account.name, Email: data.account.username, GroupIDS: groupIDs });
  
        //  for verifying the user
        await fetch(`${BASE_URL}/user/Login`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body
        })
          .then(res => res.json())
          .then(async (res) => {
            if (res.status === 200) {
              localStorage.setItem("LoginData", JSON.stringify(res));
              console.log(`${res.message}`);
              if (res.Role === 'SUPERVISOR') {
                // Handle supervisor role logic here
              }
              if (res.Role === 'ADMIN' || res.Role === 'GLOBALADMIN') {
                await localStorage.setItem('data', true);
                navigate("/support");
              }
              if (res.Role === 'WORKER') {
                // Handle worker role logic here
              }
            }
            if (res.status === 400) {
              toast.error(res?.message)
            }
          });
  
        // Call the onLogin function to set isAuthenticated to true
        console.log(onLogin);
        onLogin();
      }
    } catch (error) {
      console.error("Error in authHandler:", error);
      // Handle the error here
    }
  };
  
  return (
    <Grid container className={style.container}>
      <Grid item xs={12} md={6}>
        <Item sx={{ background: 'transparent', boxShadow: 0 }}>
          <div className={style.logodiiv1}>
            <img src='./image/loginlogo.png' className={style.logoimglatest} />
            {/* <p className={style.logotxt}>Impression</p> */}
          </div>
        </Item>
      </Grid>
      {/* <Grid item xs={0} md={6} display="flex" alignItems={'end'} justifyContent={'center'}>
            </Grid> */}
      {/* <Grid item xs={12} md={6} display="flex" alignItems={'end'} justifyContent={'center'}>
                <Item className={style.Booleanlistmego} sx={{ background: 'transparent', boxShadow: 0 }}>
                    <div className={style.userspic}>
                        <img src='./image/people2.svg' />
                    </div>
                </Item>
            </Grid> */}
      <Grid item xs={12} md={6} display={'flex'} justifyContent={'right'} alignItems={'center'}>
        <Item className={style.Booleanlistmego} display="flex" justifyContent={'center'} sx={{ background: 'transparent', boxShadow: 0 }}>

          <div className={style.singin}>
            <Box>
              <p className={style.signintxt}>Sign in</p>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  error={Boolean(formik.touched.username && formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                  name="username"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  lable='Username'
                  placeholder='Username'
                  className={style.userinput}
                  type='text'
                  style={{
                    margin: '0px'
                  }}
                />

                <TextField
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  lable='Password'
                  placeholder='Password'
                  className={style.userinput}
                  type='password'
                />
                <MicrosoftLogin
                  clientId={"75de2d90-01d2-42c2-bbda-f92bbe8a7c93"}
                  // authCallback={authHandler}
                  authCallback={(error, data, msal) => authHandler(error, data, msal)}
                  withUserData={true}
                  graphScopes={["user.read"]}
                // buttonTheme={"dark"}
                />
                <button
                  type='button'
                  onClick={() => { }}
                  className={style.frgttxt}
                > Forgot password? </button>

                <button type="submit" className={style.singbtn}> Sign in </button>
              </form>
            </Box>
          </div>
        </Item>
      </Grid>
    </Grid>
    // {/* </Box> */ }
    // </Box>
  );
}

export default Signin;
import styles from "../Css/hedar.module.css";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { doSignOut } from "../auth/firebase/auth";
let stockInterval = null;


const Nevbar = (props) => {
  const [userCount, setUserCount] = React.useState(0);
  const [ModalShow, setModalShow] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate()
  const open = Boolean(anchorEl);

  console.log(userCount, "myuser");

  const getCountuser = async () => {
    console.log(props, "myusercount");
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props?.profile?.token,
    };
    // var data = await ApiServices.GetApiCall(
    //   ApiEndpoint.USER_NOTIFICATION_COUNT,
    //   headers
    // );
    // if (!!data) {
    //   if (data.status == true) {
    //     setUserCount(data.data);
    //   } else {
    //   }
    // } else {
    // }
    console.log(userCount, "pending");
  };

  // stockInterval = setInterval(() => {
  //   getCountuser()
  // }, 3000);

  React.useLayoutEffect(() => {
    return () => {
      if (!!stockInterval) {
        clearInterval(stockInterval)
      }
    }
  }, [])

  // React.useEffect(() => {
  //   getCountuser();
  // }, []);

  const onHandleclick = () => {
  };
  const handleclick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () =>{
    doSignOut();
    setAnchorEl(null);
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={styles.maencontainer}
      >
        <Grid className={styles.textheging} item xs={12} md={6}>
          <Typography
            variant="h3"
            gutterBottom
            component="div"
            className={styles.hedingh3}
          >
            {props.data.title}
          </Typography>
          {props.data.desc && (
            <Typography sx={{ p: 1 }} className={styles.text}>
              {props.data.desc}{" "}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} className={styles.img2} md={6}>
          <Badge
            onClick={onHandleclick}
            badgeContent={userCount}
            color="secondary"
            className={styles.clrred2}
          >
            <Avatar className={styles.clrred} variant="rounded">
              <NotificationsNoneIcon
                color="action"
                className={styles.ikonbel}
              />
            </Avatar>
          </Badge>
          <Avatar variant="rounded" className={styles.pohotloho1}>
            <Avatar
              alt="Profile Picture"
              src={
                !!props.profile?.profile_photo
                  ? props.profile?.profile_photo
                  : "./image/image 3.png"
              }
              className={styles.pohotloho}
              onClick={handleclick}
            />
          </Avatar>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{marginTop:"10px"}}
          >
            {/* <MenuItem onClick={() => navigate('/faq')}>FAQ</MenuItem> */}
            <MenuItem onClick={()=>{setModalShow(true);handleSignOut()}}>Logout</MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Dialog
        open={ModalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Log-Out"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            are you sure you want to logout !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalShow(false)}>no</Button>
          <Button onClick={() => navigate('/')} autoFocus>
            yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Nevbar;

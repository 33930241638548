import * as React from 'react';
import { Card } from "react-bootstrap";
import Button from '@mui/material/Button';
import ApiServices from '../config/ApiServices';
import ApiEndpoint from '../config/ApiEndpoint';
import { toast } from 'react-toastify';
import moment from 'moment'
import Avatar from '@mui/material/Avatar';
import CollectionsIcon from '@mui/icons-material/Collections';
// import { SupportContextProvider } from "../context/SupportContext";
import style from '../Css/support.module.css'
import styles from '../Css/msg.module.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import { socket } from '../socket';
import { useEffect, useState, useRef } from 'react';
import { connectFirestoreEmulator } from 'firebase/firestore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Support = (props) => {

  const [supportListData, setSupportListData] = useState([]);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true)
  const [valueTab, setValueTab] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [search, setSearch] = React.useState(false);
  const [supportSendMessage, setSupportSendMessage] = useState('');
  const [supportSendImage, setSupportSendImage] = useState('');
  const [supportImageToBase64, setSupportImageToBase64] = useState('');
  const fileInputRef = useRef()
  const containerRef = useRef(null);

  useEffect(()=>{
      socket.on('getLatestL2Ticket', (ticket)=>{
        setCustomers(prevCustomers => [...prevCustomers, ticket.data[0]]);
      });

      return ()=>{
        socket.off('getLatestL2Ticket');
      }
  },[]);

 
  useEffect(() => {
    const handleMessageToSupport = (response) => {
        const { customerId, message } = response;

        const newMessage = {
            role: "User Request",
            message: message
        };

        if(user && user.TicketEmail===customerId){
          setUser(prevList => ({
              ...prevList,
              history: [...prevList.history, newMessage]
          }));
        }


        let customerExists = false;
        setCustomers(prevCustomers => {
            const updatedCustomers = prevCustomers.map(customer => {
                if (customer.TicketEmail === customerId) {
                    customerExists = true;
                    return {
                        ...customer,
                        history: [...customer.history, newMessage]
                    };
                } else {
                    return customer;
                }
            });

            if (!customerExists) {
                updatedCustomers.push({
                    TicketEmail: customerId,
                    history: [newMessage]
                });
            }

            return updatedCustomers;
        });

    };

    socket.on('messageToSupportFE', handleMessageToSupport);

    return () => {
        socket.off('messageToSupportFE', handleMessageToSupport);
    };
  }, []); 

  console.log(user);
  console.log(customers);

  useEffect(()=>{
    if (supportSendImage) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        setSupportImageToBase64(base64String)
      };

      reader.readAsDataURL(supportSendImage);
    }
  },[supportSendImage])

  useEffect(() => {
    getSupportListdata()
  }, [])
                
  const getSupportListdata = async () => {
    try{
        
        const response = await fetch('https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_L2_ticket_details_with_chat_history');
        const data = await response.json();

        if(data){
          setCustomers(data);
          setSupportListData(data);
          setIsLoading(false);
        }

    } catch (error) {
        console.log(error);
    } 
  }

  const tabtheme = createTheme({
    palette: {
      primary: {
        main: '#45A7A5'
      },
    }
  });

  const handleSetData = async (row) => {
    setUser(row);
  }

  const handleSendMessage = async() =>{
    if (!supportSendMessage) {
        toast.error("Please enter a message.");
        return;
    }

    const message = {
        role: "support",
        message: supportSendMessage
    };

    //console.log(message);

   
    socket.emit('messageToCustomer', { customerId: user.TicketEmail, message: {response: supportSendMessage} });

    setUser(prevList => ({
        ...prevList,
        history: [...prevList.history, message]
    }));

    setCustomers(prevCustomers => prevCustomers.map(customer => 
        customer.ID === user.ID ? { ...customer, history: [...customer.history, message] } : customer
    ));

    setSupportSendMessage('');
    setSupportSendImage('');
    
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      handleSendMessage(); 
    }
  };

  function mydate1(LastMessage) {
    const today = new Date(); 
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const dateTime = new Date(LastMessage);

    if (dateTime.toDateString() === today.toDateString()) {
        return dateTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    } else if (dateTime.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
    } else {
        return `${dateTime.getDate()}/${dateTime.getMonth() + 1}/${dateTime.getFullYear()}`;
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [user.history]);
  
  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };



  return (
    <div className="support">
      <Box className={style.supportCont}>
        {/* <SupportContextProvider> */}

          <div className="support-container d-flex">

            <Grid container className="support-container d-flex">
              <Grid sm={12} md={4} className={style.thisleft}>
                <div className={style.thisleft}>
                  {/* <Nevbar /> */}
                  <div className={style.supportTop}>
                    <ThemeProvider theme={tabtheme}>

                      <div className={style.msgdiv}>
                        <h1 className={style.msgtitel}>Message</h1>
                        <div >
                          <button className={style.btn_serchjk} onClick={(e) => {
                            setSearch(!search)
                          }} ><SearchIcon /></button>
                        </div>
                      </div>
                      {search ?
                        <div>
                          <input type="text" name="search"
                            onChange={(e) => {
                              var value = e.target.value
                              if (typeof value !== 'object') {
                                if (!value || value == '') {
                                  setCustomers(supportListData)
                                } else {
                                  var filteredData = customers?.filter((item) => {
                                    let {TicketEmail} = item
                                    const subject = 'ticket from ' +  TicketEmail;
                                    let searchValue = (subject)?.toLowerCase() + ' ' + (subject).toLowerCase()
                                    return searchValue.includes(value.toString().toLowerCase())
                                  })
                                  setCustomers(filteredData)
                                }
                              }
                            }}
                            className={style.searchbtn}
                            autoComplete="off" /></div> : <style>{`
                          display: none;
                        `}</style>}

                      <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 0, borderColor: 'divider', marginLeft: '20px' }}>
                          <Tabs value={valueTab} aria-label="basic tabs example" sx={{ borderBottom: '2px solid #EAEAEA' }}  >
                            <Tab label="All" {...tabProps(0)} className={style.MuiTabroot} />
                            {/* <Tab label="Archive" className={style.MuiTabroot} {...tabProps(1)} /> */}
                          </Tabs>
                        </Box>

                      </Box>
                    </ThemeProvider>
                  </div>

                  {/* <BasicTabs /> */}

                  <div className={style.support_list + " support-list"}>
                    {
                      !isLoading ? (
                        customers && customers.length > 0 ? (
                          customers.slice().reverse().map((row) => {

                            const { LastMessage, TicketEmail } = row;

                            return (
                              <>
                                <Grid sm={12} md={12} xs={12}>
                                  <div className={`support-list-item d-flex align-items-start ${user.ID == row.ID ? `${style.actives}` : ""}`} id={style.left} key={row.Id} onClick={() => handleSetData(row)}>
                                    <div id={style.avtarlistmasspro} style={{ width: "170px" }} >
                                      <p className="m-0">Ticket from {TicketEmail}</p>
                                    </div>

                                    <div className="list-item-right w-25 align-self-start">
                                      <p className="m-0 date-time" id={style.datetime}>{mydate1(LastMessage)}</p>
                                    </div>

                                  </div>
                                </Grid>
                              </>)
                          })
                        )
                          : (
                            <div className='loading'>
                              <h3 className={style.userlistnot}>User Not Found</h3>
                            </div>
                          )
                      ) : (
                        <div className='loading'>
                          <h3>Loading...</h3>
                        </div>
                      )
                    }
                  </div>
                  {/* <SupportList/> */}
                </div>
              </Grid>
              <Grid sm={12} md={8} className={style.this_right}>
                <div className={style.this_right}>
                  <div className={style.support_list + " support-list"} ref={containerRef} >
                    {
                      user.history?.length > 0 && (
                        user.history.map((row) => {

                          const base64Flag = 'data:image/png;base64,';
                          const { id, message, subject, image , createdAt, role } = row;

                          const date1 = moment(createdAt).format('DD-MM-YYYY')
                          const date2 = moment().format('DD-MM-YYYY')
                            let blob1 = new Blob([new Uint8Array(image?.data?.data)], {
                              type: "image/png"
                            });
                            let imageUrl = image;//URL.createObjectURL(blob1);

                          const gridAlignment = role === 'User Request' ? { xs: 10, sm: 10, md: 10 } : { xs: 10, sm: 10, md: 10, style: { marginLeft: 'auto' } };

                          return (
                            <>
                              <Grid item {...gridAlignment}>
                                <div className={`support-list-item d-flex align-items-left`} id={style.left} key={id}>
                                  <div id={style.avtarlistmasspro} style={{ wigth: "200px" }} >
                                  {/* {image !="" && <img src={imageUrl} alt="" style={{width:"300px",height:"120px",objectFit:"cover"}}/>} */}
                                    {message.length>0? (<p className="m-0">{message}</p>):''}
                                  </div>

                                  {/* <div className="list-item-right w-25 align-self-start">
                                    <p className="m-0 date-time" id={style.datetime}>{mydate1()}</p>
                                  </div> */}

                                </div>
                              </Grid>
                            </>)
                      
                        })
                      )
                    }
                  </div>
                  {user?.TicketEmail &&
                    <Box className={styles.containerhome + " " + styles.bodarbott12} style={{ display: 'flex', alignItems: 'left'}}>

                      <Box style={{ width: '90%', gap: '15px', display: 'flex' }}>
                        <Button
                          className={styles.uplodgalari}
                          variant="contained"
                          component="label"
                        >
                          <CollectionsIcon className={styles.uplodgalari12} />
                          <input
                            type="file"
                            hidden
                            accept=".jpg, .jpeg, .png"
                            ref={fileInputRef}
                            onChange={(e) => setSupportSendImage(e.target.files[0])}
                          />
                        </Button>
                        <div className='wight_full' style={{width:"100%"}}>
                          {
                            supportImageToBase64 && 
                            <div style={{width : "fit-content",position:"relative"}}>
                              <span style={{position:"absolute",top:"2px",right:"2px"}} onClick={()=>setSupportImageToBase64('')}><CancelIcon/></span>
                              <img src={supportImageToBase64} alt="" width={"250px"} height={"120px"} style={{objectFit:"cover"}}/>
                            </div>
                          }
                        <textarea placeholder='Write your message here' className={`${styles.inputhedar}`} onChange={(e) => setSupportSendMessage(e.target.value)} value={supportSendMessage} style={{width:"100%"}} onKeyPress={handleKeyPress} >
                        </textarea>
                        </div>
                      </Box>
                      <Box>
                        <div className={styles.ciriket}>
                          <Avatar className={styles.avtarbtn}>
                            <button className={styles.sendbtn} onClick={()=>handleSendMessage()}>
                              <SendIcon className={styles.ikonsendbtn} />
                            </button>
                          </Avatar>

                        </div>
                      </Box>
                    </Box>
                  } 
                </div>
              </Grid>
            </Grid>
          </div>
{/* 
        </SupportContextProvider> */}
      </Box>
    </div>

  )
}

export default Support

// const BASE_URL = 'http://localhost:8090/api/v1';
// const BASE_URL = 'http://localhost:8080/api/v1';
//const BASE_URL = 'https://4.240.52.101/api/v1';
// const BASE_URL = 'https://omegadevapi.azurewebsites.net/api/v1';

//const BASE_URL = 'https://omegadevapinet.azurewebsites.net/api/v1';

//const BASE_URL =  'https://omegauatapi.azurewebsites.net/api/v1';
// const BASE_URL =  'https://omegaqaapi.azurewebsites.net/api/v1';
const BASE_URL =  'https://omega-contractorsapi.azurewebsites.net/api/v1';

//===========OMEGA MICROSOFT CLIENT ID ===================
  const MICROSOFTCLIENTID='75de2d90-01d2-42c2-bbda-f92bbe8a7c93'

//===========DYNAPT MICROSOFT CLIENT ID ===================
//const MICROSOFTCLIENTID='e53c9150-76b8-4783-8ea3-250a3e1a0cb0'

//===========DYNAPT UAT PBI JOB REPORT URL ===================
const PBI_JOB_REPORT_URL ='https://app.powerbi.com/view?r=eyJrIjoiNWM4OTBmMjUtOGE1ZS00YmUzLWEyNWUtMzVkY2IzZjRkNzhiIiwidCI6IjcxM2ZiMWIyLTAyOWUtNDQ1Yi1hNTIxLTU2ZTQwMzhlM2Y4ZCIsImMiOjF9'

//===========OMEGA UAT PBI JOB REPORT URL ===================
// const PBI_JOB_REPORT_URL ='https://app.powerbi.com/view?r=eyJrIjoiYzQ1ZTVhZjYtZjkyNC00MDA0LWJkNzEtNDc0MjlmNTc3OWYwIiwidCI6IjcxM2ZiMWIyLTAyOWUtNDQ1Yi1hNTIxLTU2ZTQwMzhlM2Y4ZCIsImMiOjF9'

//===========OMEGA PROD PBI JOB REPORT URL ===================
// const PBI_JOB_REPORT_URL ='https://app.powerbi.com/view?r=eyJrIjoiMTg4OTc2YzMtNzA3Yy00MGViLTk2ZGEtNGI0ZGNjYzNlMjIzIiwidCI6IjcxM2ZiMWIyLTAyOWUtNDQ1Yi1hNTIxLTU2ZTQwMzhlM2Y4ZCIsImMiOjF9'


// Export variables
module.exports = {
    BASE_URL,
    MICROSOFTCLIENTID,
    PBI_JOB_REPORT_URL
};
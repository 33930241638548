import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './Component/Signin';
import SubAdmin from './Component/SubAdmin';
import LoginPage from './Component/loginPage';
import Main from './Component/Main';
import '../src/styles/globals.scss'
import Faq from './Component/faq';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './auth/authContext';

function App() {
  const { userLoggedIn } = useAuth();

  return (
    <>
      <ToastContainer />
      <Routes>
          <Route path="/" element={userLoggedIn? (<SubAdmin/>):(<LoginPage/>)} />    
      </Routes>
      </>
  );
}

export default App;
